
import { defineComponent } from 'vue';
import Game from '@/models/game.model';
import PlayerResult from '@/models/playerResult.model';
import AuthService from '@/services/AuthService';
import GameService from '@/services/GameService';
import CurrencyInput from '@/components/CurrencyInput.vue';
import ChipCounter from '@/components/ChipCounter.vue';
declare const bootstrap: any;

export default defineComponent({
  name: 'GamePlayer',
  emits: ['refreshGame'],
  components: { CurrencyInput, ChipCounter },
  props: {
    playerResult: PlayerResult,
    game: Game
  },
  data () {
    return {
      authService: new AuthService(),
      gameService: new GameService(),
      player: new PlayerResult(this.playerResult),
      buyMoreId: `buyMore${this.playerResult?.playerId}`,
      buyMoreModal: {} as any,
      updateBuyId: `updateBuy${this.playerResult?.playerId}`,
      updateBuyModal: {} as any,
      cashOutId: `cashOut${this.playerResult?.playerId}`,
      cashOutModal: {} as any,
      undoCashOutId: `undoCashOut${this.playerResult?.playerId}`,
      undoCashOutModal: {} as any,
      deletePlayerId: `deletePlayer${this.playerResult?.playerId}`,
      deletePlayerModal: {} as any,
      currentBuyAmount: 0,
      currentCashAmount: 0,
      updateBuyAmount: 0,
      updating: false,
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    }
  },
  mounted () {
    this.buyMoreModal = new bootstrap.Modal(document.getElementById(this.buyMoreId));
    this.updateBuyModal = new bootstrap.Modal(document.getElementById(this.updateBuyId));
    this.cashOutModal = new bootstrap.Modal(document.getElementById(this.cashOutId));
    this.undoCashOutModal = new bootstrap.Modal(document.getElementById(this.undoCashOutId));
    this.deletePlayerModal = new bootstrap.Modal(document.getElementById(this.deletePlayerId));
  },
  computed: {
    isCurrentPlayer (): boolean {
      return this.authService.IsLoggedIn() && this.player?.loginId === this.authService.CurrentLoginId();
    }
  },
  methods: {
    buyInAmount (): number {
      return (this.game?.buyIn || 10) - this.currentBuyAmount;
    },
    updatePlayer () {
      if (this.game && this.player) {
        this.updating = true;
        this.gameService.UpdatePlayerResult(this.game.id, this.player)
          .then(() => { this.$emit('refreshGame') })
          .finally(() => {
            this.updating = false;
            this.buyMoreModal.hide();
            this.cashOutModal.hide();
            this.undoCashOutModal.hide();
            this.updateBuyModal.hide();
            // this.deletePlayerModal.hide();
          });
      }
    },
    buyMore () {
      if (this.player) {
        this.player.buyIn += this.buyInAmount();
        this.updatePlayer();
      }
    },
    updateBuy () {
      if (this.player && this.authService.IsAdmin()) {
        this.player.buyIn = this.updateBuyAmount;
        this.updatePlayer();
      }
    },
    cashOut () {
      if (this.player) {
        this.player.cashOut = this.currentCashAmount;
        this.updatePlayer();
      }
    },
    undoCashOut () {
      if (this.player) {
        this.player.cashOut = null;
        this.updatePlayer();
      }
    },
    removePlayer () {
      if (this.game && this.player) {
        this.updating = true;
        this.gameService.DeletePlayerResult(this.game.id, this.player.playerId)
          .then(() => { this.$emit('refreshGame') })
          .finally(() => {
            this.deletePlayerModal.hide();
          });
      }
    }
  }
})
