
import { defineComponent } from 'vue';
import AuthService from '@/services/AuthService';
import GameService from '@/services/GameService';
import Game from '@/models/game.model';
import GamePlayer from '@/components/GamePlayer.vue';
import Player from '@/models/player.model';
import PlayerService from '@/services/PlayerService';
import PlayerResult from '@/models/playerResult.model';

export default defineComponent({
  name: 'Game',
  props: ['id'],
  components: {
    GamePlayer
  },
  data () {
    return {
      authService: new AuthService(),
      gameService: new GameService(),
      playerService: new PlayerService(),
      loading: true,
      playerLoading: false,
      showCreatePlayer: false,
      creatingPlayer: false,
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }),
      game: {} as Game,
      availablePlayers: [] as Player[],
      inGamePlayers: [] as string[],
      loadingPlayers: [] as string[],
      newPlayerName: ''
    }
  },
  mounted () {
    this.getGame();
  },
  methods: {
    getAvailablePlayers () {
      this.playerLoading = true;
      this.showCreatePlayer = false;
      this.playerService.GetPlayers()
        .then((result: Player[]) => {
          this.availablePlayers = result;
          this.availablePlayers.sort((a, b) => ((a.name || '') > (b.name || '')) ? 1 : -1)
          this.inGamePlayers = [];
          this.game.players.forEach(p => {
            this.inGamePlayers.push(p.playerId);
          });
        })
        .finally(() => { this.playerLoading = false });
    },
    inGame (playerId: string) {
      return this.inGamePlayers.includes(playerId);
    },
    addToGame (player: Player) {
      if (!this.inGame(player.id) && !this.addLoading(player.id)) {
        this.loadingPlayers.push(player.id);
        this.gameService.AddPlayerToGame(this.game.id, new PlayerResult({ playerId: player.id } as PlayerResult))
          .then((result: PlayerResult) => {
            this.inGamePlayers.push(result.playerId);
          })
          .finally(() => {
            this.loadingPlayers.splice(this.loadingPlayers.indexOf(player.id), 1);
          });
      }
    },
    addLoading (playerId: string) {
      return this.loadingPlayers.includes(playerId);
    },
    getGame () {
      this.gameService.GetGame(this.id)
        .then((result: Game) => {
          this.game = result;
          this.sortPlayers();
        })
        .finally(() => { this.loading = false });
    },
    toggleLock () {
      this.gameService.ToggleLock(this.game.id)
        .then((result) => {
          this.game = new Game(result);
          this.sortPlayers();
        })
    },
    sortPlayers () {
      if (!this.authService.IsLoggedIn() || this.game.players.length === 0) return;
      const currentPlayerIndex = this.game.players.findIndex(p => p.loginId === this.authService.CurrentLoginId());
      if (currentPlayerIndex == null) return;
      const player = this.game.players.splice(currentPlayerIndex, 1);
      this.game.players.unshift(player[0]);
    },
    createPlayer: function (e: any) {
      if (this.newPlayerName == null || this.newPlayerName === '' || this.newPlayerName === ' ') return;

      this.creatingPlayer = true;
      const newPlayer = new Player({
        name: this.newPlayerName,
        lastLogIn: undefined
      });
      this.playerService.CreatePlayer(newPlayer)
        .then((player: Player) => {
          this.availablePlayers.push(player);
          this.availablePlayers.sort((a, b) => ((a.name || '') > (b.name || '')) ? 1 : -1);
        })
        .finally(() => {
          this.creatingPlayer = false;
          this.newPlayerName = '';
          this.showCreatePlayer = false;
        });

      e.preventDefault();
    }
  }
})
