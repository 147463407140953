
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChipCounter',
  data () {
    return {
      gray: 0,
      red: 0,
      green: 0,
      black: 0,
      purple: 0,
      yellow: 0
    }
  },
  methods: {
    total (): number {
      return this.gray * 0.01 + this.red * 0.05 + this.green * 0.25 + this.black * 1 + this.purple * 5 + this.yellow * 10;
    },
    emitTotal () {
      this.$emit('newTotal', this.total());
    }
  }
})
